import React, { useContext, useRef, useEffect, memo } from "react";
import { ProductsContext, DispatchsContext } from "../context/products.context";

// import Swiper core and required components
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import SingleSlide from './SingleSlide';

import "../style/local/carousel-style.scss";

// install Swiper components
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

function Carousel() {
    const appState = useContext(ProductsContext);
    const dispatch = useContext(DispatchsContext);
    const swiperRef = useRef(null);

    const handleChange = swiper => {
        dispatch({ type: "SET_SLIDE_INDEX", value: swiper.activeIndex });
    }

    useEffect(() => {
        if (appState.slideItemCartIndex !== -1) {
            swiperRef.current.swiper.slideTo(appState.slideItemCartIndex);
        }

    }, [appState.slideItemCartIndex]);


    const params = {
        freeMode: true,
        freeModeSticky: true,
        slidesPerView: 2,
        centeredSlides: true,
        slideToClickedSlide: true,
        ref: swiperRef,
        // onSlideChange: handleChange,
        onTransitionEnd: handleChange
    }


  return (
    <Swiper { ...params }>

        {appState.products.map((product, index) => {
            return (
                <SwiperSlide key={product.id}>
                    <SingleSlide 
                        key={product.id} 
                        img={product.img} 
                        name={product.name} 
                        id={product.id} 
                        quantity={product.quantity}
                        slideIndex={index}
                    />
                </SwiperSlide>
            )
        })}

    </Swiper>
    
  );
};



export default memo(Carousel);