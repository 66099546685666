import productImg0 from "./style/global/images/product-0.jpg";
import productImg1 from "./style/global/images/product-1.jpg";
import productImg2 from "./style/global/images/product-2.jpg";
import productImg3 from "./style/global/images/product-3.jpg";
import productImg4 from "./style/global/images/product-4.jpg";
import productImg5 from "./style/global/images/product-5.jpg";
import productImg6 from "./style/global/images/product-6.jpg";
import productImg7 from "./style/global/images/product-7.jpg";
import productImg8 from "./style/global/images/product-8.jpg";
import productImg9 from "./style/global/images/product-9.jpg";
import productImg10 from "./style/global/images/product-10.jpg";
import productImg11 from "./style/global/images/product-11.jpg";
import productImg12 from "./style/global/images/product-12.jpg";
import productImg13 from "./style/global/images/product-13.jpg";
import productImg14 from "./style/global/images/product-14.jpg";

export default [
    {
        name: "אגס",
        brand: "מותג אגס",
        id: 0,
        price: 0.99,
        quantity: 0,
        img: productImg0
    },

    {
        name: "אננס",
        brand: "מותג אננס",
        id: 1.11,
        price: 1.22,
        quantity: 0,
        img: productImg1
    },

    {
        name: "רימון",
        brand: "מותג רימון",
        id: 2,
        price: 2.99,
        quantity: 0,
        img: productImg2
    },

    {
        name: "ענבים",
        brand: "מותג ענבים",
        id: 3,
        price: 3.99,
        quantity: 0,
        img: productImg3
    },

    {
        name: "גמבה",
        brand: "מותג גמבה",
        id: 4,
        price: 4.99,
        quantity: 0,
        img: productImg4
    },

    {
        name: "בננה",
        brand: "מותג בננה",
        id: 5,
        price: 5.99,
        quantity: 0,
        img: productImg5
    },

    {
        name: "קיווי",
        brand: "מותג קיווי",
        id: 6,
        price: 6.99,
        quantity: 0,
        img: productImg6
    },

    {
        name: "תפוח אדום",
        brand: "מותג תפוח אדום",
        id: 7,
        price: 7.99,
        quantity: 0,
        img: productImg7
    },

    {
        name: "תפוח ירוק",
        brand: "מותג תפוח ירוק",
        id: 8,
        price: 8.99,
        quantity: 0,
        img: productImg8
    },

    {
        name: "תפוז",
        brand: "מותג תפוז",
        id: 9,
        price: 9.99,
        quantity: 0,
        img: productImg9
    },

    {
        name: "אנונה",
        brand: "מותג אנונה",
        id: 10,
        price: 10.99,
        quantity: 0,
        img: productImg10
    },

    {
        name: "אפרסמון",
        brand: "מותג אפרסמון",
        id: 11,
        price: 11.99,
        quantity: 0,
        img: productImg11
    },

    {
        name: "עגבניה",
        brand: "מותג עגבניה",
        id: 12,
        price: 12.99,
        quantity: 0,
        img: productImg12
    },

    {
        name: "תפוח צהוב",
        brand: "מותג תפוח צהוב",
        id: 13,
        price: 13.99,
        quantity: 0,
        img: productImg13
    },

    {
        name: "אשכולית",
        brand: "מותג אשכולית",
        id: 14,
        price: 14.99,
        quantity: 0,
        img: productImg14
    },

]
