import React, { useContext } from "react";

import { ProductsContext } from "../context/products.context";
import "../style/local/product-price-style.scss";



function ProductPrice() {

    const { products, slideIndex } = useContext(ProductsContext);
    const curProduct = products[slideIndex];

    const price = curProduct.price.toString().split(".");
    const shekel = price[0];
    const pennies = price[1];

    return (
        <div className="price-container">
            <div className="price">
                <span className="currency">₪</span>
                <span className="shekel">{shekel}.</span>
                <span className="pennies">{pennies}</span>
            </div>
            <span className="unit">ליח׳</span>
        </div>
    );
}

// function ProductPrice({ price }) {
//     return (
//         <div className="price-container">
//             <span className="price">{`${price} ₪`}</span>
//             <span className="unit">ליח׳</span>
//         </div>
//     );
// }

export default ProductPrice;