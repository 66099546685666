import React, { useContext, useRef, useState } from "react";
import { ProductsContext, DispatchsContext } from "../context/products.context";
import { Swipeable } from 'react-swipeable';


import "../style/local/single-slide-style.scss";

function SingleSlide({ img, name, id, quantity, slideIndex }) {
    const appState = useContext(ProductsContext);
    const dispatch = useContext(DispatchsContext);
    const nodeRef = useRef(null);
    const [increaseBtnWasClicked, setIncreaseBtnWasClicked] = useState(false);
    const slideAnimate = increaseBtnWasClicked ? 'swiper-slide--animate' : '';

    const increaseQuantity = () => {
        const itemExistsInShoppingCart = quantity !== 0;

        // if this is the first time we add item to cart
        // we would like to update the items counter in shopping cart.
        if (!itemExistsInShoppingCart) {
            dispatch({ type: "ADD_PRODUCT_TO_CART", slideIndex: slideIndex});
        }

        setIncreaseBtnWasClicked(true);

        dispatch({ type: "INCREASE_QUANTITY" });

        // if shopping cart is close we want to toggle it.
        // else, we will keep it open and only change the title
        // backgroun color.
        appState.isShoppingCartOpen ? 
            keepShoppingCartOpen() : 
            toggleShoppingCart();
    }

    const toggleShoppingCart = () => {
        dispatch({ type: "EXPAND_SHOPPONG_CART" });
        dispatch({ type: "ADD_SUCCESS_COLOR_FROM_SHOPPING_CART_TITLE" });

        setTimeout(() => {
            setIncreaseBtnWasClicked(false);
            dispatch({ type: "HIDE_SHOPPONG_CART" });
            dispatch({ type: "REMOVE_SUCCESS_COLOR_FROM_SHOPPING_CART_TITLE" });
        }, 800);
    }

    const keepShoppingCartOpen = () => {
        dispatch({ type: "ADD_SUCCESS_COLOR_FROM_SHOPPING_CART_TITLE" });

        setTimeout(() => {
            // swiperSlide.classList.remove('swiper-slide--animate');
            setIncreaseBtnWasClicked(false);
            dispatch({ type: "REMOVE_SUCCESS_COLOR_FROM_SHOPPING_CART_TITLE" });
        }, 800);
    }

    const decreaseQuantity = () => {
        if (quantity === 1) {
            dispatch({ type: "REMOVE_PRODUCT_FROM_CART" });
        }

        if (quantity > 0) {
            dispatch({ type: "DECREASE_QUANTITY", id: id })
        }
    }

    const handleSwiping = e => {
        let element = e.event.target;
        const deltaY = e.deltaY;
        const absY = e.absY;
        const scale = 1 - ((absY - 20) / 150);
        const translate = (absY - 20);
        const isSwipeDown = deltaY < -20;
        const itemReachToTarget = absY >= 100;

        // match the correct element
        if (element.className !== 'slide-img-container') {
            element = element.closest(".slide-img-container");
        }

        // item didn't reach to target -> reset transform.
        if (isSwipeDown && !itemReachToTarget) {
            element.style.transform = `scale(${scale}) translateY(${translate}px)`;

            appState.isShoppingCartOpen && 
            dispatch({ type: "REMOVE_SUCCESS_COLOR_FROM_SHOPPING_CART_TITLE" });
        }

        // item reach to target and shopping cart close.
        else if (isSwipeDown && itemReachToTarget && !appState.isShoppingCartOpen) {
            dispatch({ type: "EXPAND_SHOPPONG_CART" });
            dispatch({ type: "ADD_SUCCESS_COLOR_FROM_SHOPPING_CART_TITLE" });
        }

        // item reach to target and shopping cart open.
        else if (isSwipeDown && itemReachToTarget && appState.isShoppingCartOpen) {
            dispatch({ type: "ADD_SUCCESS_COLOR_FROM_SHOPPING_CART_TITLE" });
        }
    };

    const handleSwiped = e => {
        let element = e.event.target;
        const deltaY = e.deltaY;
        const absY = e.absY;
        const isSwipeDown = deltaY < -20;
        const itemReachToTarget = absY >= 100;
        const itemExistsInShoppingCart = quantity !== 0;

        // match the correct element
        if (element.className !== 'slide-img-container') {
            element = element.closest(".slide-img-container");
        }   
        
        // item didn't reach to target -> reset item transform.
        if (isSwipeDown && !itemReachToTarget) {
            element.style.transform = `scale(1) translateY(0px)`;
        }

        // item react to target.
        else if (isSwipeDown && itemReachToTarget) {
            element.style.transform = `scale(1) translateY(0px)`;

            setIncreaseBtnWasClicked(true);
            dispatch({ type: "INCREASE_QUANTITY"});
            
            
            if (!itemExistsInShoppingCart) {
                dispatch({ type: "ADD_PRODUCT_TO_CART", slideIndex: slideIndex});
            }
      
            setTimeout(() => {
                dispatch({ type: "REMOVE_SUCCESS_COLOR_FROM_SHOPPING_CART_TITLE" });
                setIncreaseBtnWasClicked(false);
                dispatch({ type: "HIDE_SHOPPONG_CART" });
            }, 1000);   
        }
    }

    const swipableSettings = {
        delta: 20, 
        onSwiped: handleSwiped,
        onSwiping: handleSwiping,
        preventDefaultTouchmoveEvent: true,
        className: `slide-img-container ${slideAnimate}`,  
        ref: nodeRef
    }


    return (
        <div className="single-slide">
            <button onClick={decreaseQuantity} className="decrease-btn">-</button>

            {slideIndex === appState.slideIndex ? (
                <Swipeable {...swipableSettings}>
                    <img 
                        className="product-img" 
                        src={img} 
                        alt={name}
                    />
                    <div className="quantity">{quantity}</div>
                </Swipeable>

            ) : (

            <div 
                className={`slide-img-container ${slideAnimate}`} 
                ref={nodeRef}
            >
                <img 
                    className="product-img" 
                    src={img} 
                    alt={name}
                />
                <div className="quantity">{quantity}</div>
            </div>

            )}
            
            <button onClick={increaseQuantity} className="increase-btn">+</button>
        </div>
    )

   

    // return (
    //     <div className="single-slide">
    //         {quantity !== 0 && (
    //             <button onClick={decreaseQuantity} className="decrease-btn">-</button>
    //         )}

    //         {slideIndex === appState.slideIndex ? (
    //             <Draggable {...draggableSettingd} >
    //                 <div className="draggable-wrapper" ref={nodeRef}>
    //                     <div className={`slide-img-container ${slideAnimate}`}>
    //                         <img 
    //                             className="product-img" 
    //                             src={img} 
    //                             alt={name}
    //                         />
    //                         <div className="quantity">{quantity}</div>
    //                     </div>
    //                 </div>
    //             </Draggable>

    //         ) : (
    //             <div className="slide-img-container" ref={nodeRef}>
    //                 <img 
    //                     className="product-img" 
    //                     src={img} 
    //                     alt={name}
    //                 />
    //                 <div className="quantity">{quantity}</div>
    //             </div>
    //         )}

    //         <button onClick={increaseQuantity} className="increase-btn">+</button>
    //     </div>
    // )

}

export default SingleSlide;