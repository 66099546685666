const reducer = (state, action) => {

    const currProduct = state.products[state.slideIndex];

    switch(action.type) {
        case "SET_SLIDE_INDEX":
            state.slideIndex = action.value;
            return;

        case "SET_SLIDE_ITEM_CART_INDEX":
            state.slideItemCartIndex = action.value;
            return;

        case "INCREASE_QUANTITY":
            currProduct.quantity++;
            
            state.shoppingCart.map(item => {
                return (item.id === currProduct.id ? item.quantity = currProduct.quantity : item);
            });
            return;

        case "DECREASE_QUANTITY":
            currProduct.quantity--;

            state.shoppingCart.map(item => {
                return (item.id === currProduct.id ? item.quantity = currProduct.quantity : item);
            });
            return;

        case "ADD_PRODUCT_TO_CART":
            state.shoppingCart.push({ ...currProduct, slideIndex: action.slideIndex });
            return;

        case "REMOVE_PRODUCT_FROM_CART":
            const index = state.shoppingCart.findIndex(item => item.id === currProduct.id);
            
            if (index > -1) {
                state.shoppingCart.splice(index, 1);
            }
            return;
            
        case "TOGGLE_SHOPPONG_CART":
            state.isShoppingCartOpen = !state.isShoppingCartOpen;
            return;
        
        case "EXPAND_SHOPPONG_CART":
            state.isShoppingCartOpen = true;
            return;
        
        case "HIDE_SHOPPONG_CART":
            state.isShoppingCartOpen = false;
            return;
        
        case "ADD_SUCCESS_COLOR_FROM_SHOPPING_CART_TITLE":
            state.productWasAdd = true;
            return;
        
        case "REMOVE_SUCCESS_COLOR_FROM_SHOPPING_CART_TITLE":
            state.productWasAdd = false;
            return;

        default: 
            return state;
    }
};

export default reducer;