import React, { useContext, memo, useRef} from "react";
import { TransitionGroup, CSSTransition } from 'react-transition-group';

/* Local components */
import { ProductsContext, DispatchsContext } from "../context/products.context";

import shoppingCartIcon from "../style/global/images/shopping-cart.svg";
import expandArrowIcon from "../style/global/images/expand-arrow.svg";
import "../style/local/shopping-cart-style.scss";

function ShoppingCart() {
    const nodeRef = useRef(null);
    const appState = useContext(ProductsContext);
    const dispatch = useContext(DispatchsContext);

    const selectedProducts = appState.products.filter(product => product.quantity > 0);
    const totalNumOfProductsText = selectedProducts.length === 1 ? 'פריט': 'פריטים';
        
    const expandProductsList = appState.isShoppingCartOpen ? 'products-list-wrapper--expand' : '';
    const expandTitleContainer = appState.isShoppingCartOpen ? 'title-container--expand' : '';
    const changeTitleColor = appState.productWasAdd ? 'title-container--green-color' : '';

    const setProductSlide = id => {
        const selectedItem = appState.shoppingCart.find(item => item.id === id);
        dispatch({ type: "SET_SLIDE_ITEM_CART_INDEX", value: selectedItem.slideIndex });
    }

    return (
        <div className="shopping-cart">
            <div 
                onClick={() => dispatch({ type: "TOGGLE_SHOPPONG_CART" })} 
                className={`title-container ${expandTitleContainer} ${changeTitleColor}`}>

                {appState.isShoppingCartOpen ? (
                    <>
                        <h2 className="title">
                            {`${appState.shoppingCart.length} ${totalNumOfProductsText}`}
                        </h2>
                        <img className="down-arrow-icon" src={expandArrowIcon} alt="down-arrow-icon" />
                    </>
                    ) : (
                    <>
                        <div className="products-counter">
                            {appState.shoppingCart.length}
                        </div>

                        <img 
                            className="shopping-cart-icon" 
                            src={shoppingCartIcon} 
                            alt="shopping-cart-icon" 
                        />
                    </>
                    )
                }
            </div>

            <div className={`products-list-wrapper ${expandProductsList}`}>
                <TransitionGroup className="products-list">
                    {appState.shoppingCart.map(product => (
                        <CSSTransition
                            nodeRef={nodeRef}
                            key={product.id}
                            timeout={500}
                            classNames="transition-item"
                        >
        
                            <div className="products-list__item" ref={nodeRef}>
                                <img 
                                    onClick={() => setProductSlide(product.id)} 
                                    src={product.img} 
                                    alt={product.name} 
                                />
                                <div className="quantity">{product.quantity}</div>
                            </div>
                        
                        </CSSTransition>
                        
                    ))}
                    </TransitionGroup>
            </div>


            {/* <div className={`products-list ${expandProductsList}`}>
                <TransitionGroup className="todo-list">
                    {appState.shoppingCart.map(product => (
                        <CSSTransition
                            nodeRef={nodeRef}
                            key={product.id}
                            timeout={500}
                            classNames="transition-item"
                        >
        
                            <div className="products-list__item" ref={nodeRef}>
                                <img 
                                    onClick={() => setProductSlide(product.id)} 
                                    src={product.img} 
                                    alt={product.name} 
                                />
                                <div className="quantity">{product.quantity}</div>
                            </div>
                        
                        </CSSTransition>
                        
                    ))}
                    </TransitionGroup>
            </div> */}

        </div>
    );
}


export default memo(ShoppingCart);