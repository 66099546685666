import React, { useContext } from "react";

import { ProductsContext } from "../context/products.context";
import "../style/local/main-container-style.scss";

function MainContainer(props) {

    const { isShoppingCartOpen } = useContext(ProductsContext);

    return (
        <div className={`main-container ${isShoppingCartOpen ? 'main-container--push-up' : ''}`}>
            {props.children}
        </div>
    );
}


export default MainContainer;