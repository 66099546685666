import React, { useContext } from "react";
import "../style/local/product-main-details-style.scss";
import { ProductsContext } from "../context/products.context";



function ProductMainDetils() {
    const { products, slideIndex } = useContext(ProductsContext);
    const curProduct = products[slideIndex];

    return (
        <div className="product-main-details">
            <h1 className="product-name">{curProduct.name}</h1>
            <h2 className="product-brand">{curProduct.brand}</h2>            
        </div>
    );
}

// function ProductMainDetils ({ name, brand }) {
//     return (
//         <div className="product-main-details">
//             <h1 className="product-name">{name}</h1>
//             <h2 className="product-brand">{brand}</h2>            
//         </div>
//     );
// }

export default ProductMainDetils;