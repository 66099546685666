import React from 'react';

/* Local componenets */
import ProductMainDetils from "./components/ProductMainDetails";
import ProductPrice from './components/ProductPrice';
// import ProductsCarousel from "./components/ProductsCarousel";
import ShoppingCart from './components/ShoppingCart';
// import { ProductsContext } from "./context/products.context";

/* Global style */
import 'normalize.css';
import './style/global/global.scss';

import './style/local/app-style.scss';
import MainContainer from './components/MainContainer';
import Carousel from './components/Carousel';


function App() {
  // const [slideIndex, setSlideIndex] = useState(0);
  // const [selectedShoppingCartProduct, setSelectedShoppingCartProduct] = useState();
  // const products = useContext(ProductsContext);
  // const curProduct = products[slideIndex];


  return (
    <div className="App">
      <MainContainer>

        <ProductMainDetils />
        <ProductPrice />
        <Carousel />
        {/* <ProductsCarousel /> */}
      </MainContainer>
      <ShoppingCart />
    </div>
  );
}



// function App() {
//   const [slideIndex, setSlideIndex] = useState(0);
//   const [selectedShoppingCartProduct, setSelectedShoppingCartProduct] = useState();

//   const products = useContext(ProductsContext);
//   const curProduct = products[slideIndex];

//   return (
//     <div className="App">
//       <ProductMainDetils 
//         name={curProduct.name} 
//         brand={curProduct.brand}
//       />
//       <ProductPrice price={curProduct.price} />
      
//       <ProductsCarousel 
//         setSlideIndex={setSlideIndex} 
//         selectedShoppingCartProduct={selectedShoppingCartProduct}
//       />
//       <ShoppingCart 
//         setSelectedShoppingCartProduct={setSelectedShoppingCartProduct} 
//       />
//     </div>
//   );
// }

export default App;
