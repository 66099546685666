import React, { createContext } from "react";
import { useImmerReducer } from "use-immer";
import fakeData from "../fakeData";
import productReducer from "../reducers/product.reducer";

export const ProductsContext = createContext();
export const DispatchsContext = createContext();

const initialState = {
    products: fakeData,
    slideIndex: 0,
    shoppingCart: [],
    isShoppingCartOpen: false,
    productWasAdd: false,
    slideItemCartIndex: -1
}

export function ProductsProvider(props) {
    // const [state, dispatch] =  useReducer(productReducer, fakeData);

    const [state, dispatch] =  useImmerReducer(productReducer, initialState);

    return (
        <ProductsContext.Provider value={state}>
            <DispatchsContext.Provider value={dispatch}>
                {props.children}
            </DispatchsContext.Provider>
        </ProductsContext.Provider>
    )
}